import { Stack } from '@mui/material';
import { ResellerBadge } from 'components/ResellerBadge/reseller-badge';
import { useState } from 'react';
import { ResellerBadgeModel } from 'types';

interface ResellerBadgeListProps {
  badges: ResellerBadgeModel[];
}

export function ResellerBadgeList(props: ResellerBadgeListProps) {
  const { badges } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  if (badges.length <= 0) {
    return null;
  }

  return (
    <Stack
      direction={'row'}
      gap={'6px'}
      justifyContent={'space-between'}
      width={'100%'}
      alignItems={'flex-start'}
      onClick={() => setIsOpen(!isOpen)}
    >
      <Stack
        direction={'row'}
        gap={'6px'}
        width={'100%'}
        maxWidth={'80%'}
        height={'fit-content'}
        flexWrap={'wrap'}
      >
        {badges.map((badge, index) => (
          <ResellerBadge key={index} badge={badge} />
        ))}
      </Stack>
    </Stack>
  );
}
