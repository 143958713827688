import { useContext, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  useTheme,
  Stack,
  IconButton
} from '@mui/material';
import { Container } from 'components/Container';
import { appGasService } from 'config/services/appgas';
import { useNavigate } from 'react-router-dom';
import AccessTime from '@mui/icons-material/AccessTime';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import axios from 'axios';
import { formatPrice } from '../../../helpers/formatPrice';
import { ResellerBadgeModel, ResellerPaymentMethod } from 'types';
import { toast } from 'react-toastify';
import Loading from 'components/Loading';
import { trackEvent } from 'config/services/adjustTracking';
import {
  eventBeginCheckout,
  eventAllResellersClosed,
  eventNoResellers,
  eventNoResellersAvaiable,
  eventClosedResellers
} from 'config/services/gtmEvents';
import { ResellerBadgeList } from './ResellerBadgeList';
import { useAddresses } from 'hooks/useAddressesContext';
import { ResellerContext } from 'providers/reseller-context/reseller-context';
import { ChosenProductsContext } from 'providers/chosen-products-context/chosen-products-contex';
import { SourceIntegrationContext } from 'providers/source-integration-context/source-integration-context';
import { readStorage } from 'hooks/useLocalStorage';
import HelpIcon from '@mui/icons-material/Help';
import RankingAppgasModal from './RankingAppgasModal';
interface IChosenProductsState {
  id: string;
  name: string;
  desc: string;
  price: number;
  picture: string;
  quantity: number;
}

interface IProps {
  products: IChosenProductsState[];
}

export type Reseller = {
  full_display_name: string;
  id: string;
  name: string;
  brand: string;
  brand_color: string;
  delivery_time: string;
  payment_methods: ResellerPaymentMethod[];
  enabled: boolean;
  total: number;
  rank: number;
  sales_channel: string;
  products: {
    id: string;
    unit_price: number;
    quantity: number;
    total: number;
    zone_id: string;
  }[];
  brand_id: string;
  display_name: string;
  badges: ResellerBadgeModel[];
};

export interface IProductsState {
  id: string;
  name: string;
  desc: string;
  picture: string;
  price: number;
  quantity: number;
}

export default function ChoiceResellers({ products }: IProps) {
  const theme = useTheme();
  const navigate = useNavigate();

  const customerToken = readStorage('customer_token');
  const customerId = readStorage('customer_id');

  const { addressDataInput, createAddress } = useAddresses();

  const address = addressDataInput;

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [resellers, setResellers] = useState<Reseller[]>([]);
  // const [sortedBy, setSortedBy] = useState<'price' | 'time'>('time');

  const { setReseller } = useContext(ResellerContext);

  const setChosenReseller = (input: Reseller) => setReseller(input);

  const { chosenProducts, setChosenProducts } = useContext(
    ChosenProductsContext
  );

  const { integration } = useContext(SourceIntegrationContext);

  const sourceIntegration = integration.source;

  const sales_channel = (sourceIntegration: string) => {
    const integrationToSalesChannelMap: Record<string, string> = {
      ifood: 'ifood',
      consigaz: 'consigaz'
    };

    return integrationToSalesChannelMap[sourceIntegration] || 'app';
  };

  useEffect(() => {
    if (!address) {
      return navigate('/');
    }

    if (chosenProducts?.length <= 0) {
      return;
    }

    setError('');
    setLoading(true);

    (async () => {
      try {
        setResellers([]);
        setChosenProducts(products);

        const { data } = await appGasService.post(
          `/v2/integrations/resellers`,
          {
            address: {
              ...address,
              latitude: address.latitude || address.lat,
              longitude: address.longitude || address.long
            },
            products,
            sales_channel: sales_channel(sourceIntegration)
          }
        );

        if (!data.length) {
          trackEvent({ eventToken: 'Sem revenda', sourceIntegration });
          eventNoResellers(address.state);
          eventNoResellersAvaiable(address.state);
        }

        const checkClosedResellers = data.filter(
          (resellerItem: Reseller) => resellerItem.enabled === false
        );

        if (checkClosedResellers.length === data.length) {
          trackEvent({ eventToken: 'Revenda Fechada', sourceIntegration });
          eventAllResellersClosed(address, new Date());
          eventClosedResellers(address, new Date());
        }

        setLoading(false);
        setResellers(data);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setError(error?.response?.data?.message);
        }

        setLoading(false);
        toast.error(
          'Ocorreu um erro ao selecionar a revenda, por favor tente mais tarde.',
          {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          }
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, address]);

  async function handleChooseReseller(reseller: Reseller) {
    if (!reseller.enabled) {
      return;
    }
    const total = 'R$ ' + reseller.total.toString().replace('.', ',');
    if (!products.length) {
      toast.warn('Por favor, selecione um produto.', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });

      return;
    }

    const updatedProductChoice = chosenProducts.map((product) => {
      const resellerProduct = reseller.products.find(
        (p) => p.id === product.id
      );

      if (resellerProduct) {
        product.price = resellerProduct?.total;
        product.quantity = resellerProduct?.quantity;
      }

      return product;
    });

    setChosenProducts(updatedProductChoice);
    setChosenReseller(reseller);

    const cartItems = updatedProductChoice
      .map(
        (product) =>
          'product: ' +
          product.name +
          ' - quantity: ' +
          product.quantity +
          ' - price: ' +
          product.price
      )
      .join(' | ');
    eventBeginCheckout(updatedProductChoice, reseller.total, cartItems, total);
    trackEvent({ eventToken: 'Escolheu revenda', sourceIntegration });
    const destination = '/cart';

    if (customerId || customerToken) {
      if (!address?.id) {
        await createAddress();
      }
    }

    if (!customerToken) {
      navigate('/create-lead');
    } else {
      navigate(destination);
    }
  }

  return (
    <Box sx={{ borderTop: `solid 1px ${theme.palette.grey[300]}` }}>
      <Container>
        {!!resellers.length && (
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Stack direction={'row'} alignItems={'center'}>
              <Typography
                sx={{
                  mt: '16px',
                  mb: '16px',
                  fontWeight: theme.typography.fontWeightLight
                }}
              >
                Selecionar revenda
              </Typography>
              <IconButton onClick={() => setIsOpen(true)}>
                <HelpIcon
                  sx={{
                    width: '18px',
                    height: '18px',
                    color: '#757575'
                  }}
                />
              </IconButton>
            </Stack>
            {/* <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={'8px'}
            >
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                onClick={() => setSortedBy('time')}
                sx={{ cursor: 'pointer' }}
              >
                <AccessTime
                  sx={{
                    fontSize: '16px',
                    mr: '5px',
                    color:
                      sortedBy == 'time'
                        ? theme.palette.primary.xdark
                        : theme.palette.grey[600]
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: '600',
                    lineHeight: '18px',
                    color:
                      sortedBy == 'time'
                        ? theme.palette.primary.xdark
                        : theme.palette.grey[600]
                  }}
                >
                  Tempo
                </Typography>
              </Stack>
              <Box
                sx={{
                  height: '8px',
                  width: '1px',
                  backgroundColor: theme.palette.grey[400]
                }}
              />
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                onClick={() => setSortedBy('price')}
                sx={{ cursor: 'pointer' }}
              >
                <AccountBalanceWalletOutlined
                  sx={{
                    fontSize: '16px',
                    mr: '5px',
                    color:
                      sortedBy == 'price'
                        ? theme.palette.primary.xdark
                        : theme.palette.grey[600]
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: '600',
                    lineHeight: '18px',
                    color:
                      sortedBy == 'price'
                        ? theme.palette.primary.xdark
                        : theme.palette.grey[600]
                  }}
                >
                  Preço
                </Typography>
              </Stack>
            </Stack> */}
          </Stack>
        )}

        {loading && !resellers.length && !error && <Loading />}

        {!loading && !resellers.length && !error && (
          <Box sx={{ textAlign: 'center' }}>
            <ErrorOutlineIcon
              fontSize="large"
              sx={{ mt: '40px', color: theme.palette.grey[400] }}
            />
            <Typography
              variant="subtitle2"
              sx={{ fontSize: '1rem', color: theme.palette.primary.dark }}
            >
              Sem revendas cadastradas
            </Typography>
            <Typography
              variant="caption"
              sx={{ color: theme.palette.grey[400] }}
            >
              Atualmente não temos revendas no endereço informado
            </Typography>
          </Box>
        )}

        {!loading && !resellers.length && error && (
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              variant="subtitle2"
              sx={{
                mt: '40px',
                fontSize: '1rem',
                color: theme.palette.error.main
              }}
            >
              {error}
            </Typography>
          </Box>
        )}
        {!!resellers.length &&
          resellers.map((reseller) => (
            <Card
              key={reseller.id}
              variant="custom"
              sx={{
                mb: '12px',
                height: 'auto',
                display: 'flex',
                cursor: reseller.enabled ? 'pointer' : 'default',
                alignItems: 'center',
                border: reseller.rank >= 90 ? '1px solid #2CB7D6' : '',
                boxShadow:
                  reseller.rank >= 90 ? '0px 4px 4px 0px #0000001F' : ''
              }}
              onClick={() => handleChooseReseller(reseller)}
            >
              <CardContent
                sx={{
                  p: '12px !important',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  backgroundColor: reseller.enabled ? '' : '#FBFBFB',
                  gap: '8px'
                }}
              >
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  width={'100%'}
                >
                  <Typography
                    sx={{
                      height: '100%',
                      p: '2px 6px',
                      width: 'auto',
                      color: '#FFFF',
                      fontWeight: 700,
                      fontSize: '10px',
                      borderRadius: '5px',
                      textAlign: 'center',
                      lineHeight: '15px',
                      opacity: reseller.enabled ? '1' : '0.4',
                      background: reseller.brand_color
                    }}
                  >
                    {reseller.brand}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: theme.palette.grey[600],
                      fontStyle: 'normal',
                      opacity: reseller.enabled ? '1' : '0.4',
                      fontSize: '12px'
                    }}
                  >
                    {reseller.full_display_name}
                  </Typography>
                </Stack>
                <Stack
                  direction={'row'}
                  gap={'12px'}
                  alignItems={'center'}
                  width={'100%'}
                  onClick={() => handleChooseReseller(reseller)}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      fontWeight: '700',
                      color: theme.palette.grey[800],
                      opacity: reseller.enabled ? '1' : '0.4',
                      alignItems: 'center',
                      fontSize: '14px'
                    }}
                  >
                    {formatPrice(reseller.total)}
                  </Typography>
                  <Stack direction={'row'} alignItems={'center'}>
                    <AccessTime
                      fontSize="small"
                      sx={{
                        mr: '5px',
                        color: reseller.enabled
                          ? theme.palette.grey[400]
                          : theme.palette.grey[500]
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '12px',
                        lineHeight: '18px',
                        fontWeight: reseller.enabled ? '600' : '700',
                        color: reseller.enabled
                          ? theme.palette.grey[700]
                          : theme.palette.grey[800]
                      }}
                    >
                      {reseller.enabled ? reseller.delivery_time : 'Fechado'}
                    </Typography>
                  </Stack>
                </Stack>
                {reseller.badges && reseller.badges.length > 0 && (
                  <ResellerBadgeList badges={reseller.badges} />
                )}
              </CardContent>
            </Card>
          ))}
        <RankingAppgasModal onClose={() => setIsOpen(false)} open={isOpen} />
      </Container>
    </Box>
  );
}
