import {
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { Help, LockOutlined } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import { Order } from 'types';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import icon_driver from '../../../../assets/driver-icon.png';
import icon_pin from '../../../../assets/pin-icon.png';
import character_driver from '../../../../assets/character-driver.png';
import { grey } from '@mui/material/colors';
import { ConfirmationCodeModal } from '../ConfirmationCode/confirmation-code-modal';

interface IProps {
  order: Order;
}

export function DriverMap(props: IProps) {
  const theme = useTheme();
  const { order } = props;
  const mapRef = useRef<google.maps.Map | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const containerStyle = {
    width: '100%',
    height: '400px'
  };

  const center = {
    lat: -23.55052,
    lng: -46.633308
  };

  useEffect(() => {
    if (mapRef.current && order) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend({
        lat: order.driver_latitude,
        lng: order.driver_longitude
      });
      bounds.extend({ lat: order.address.lat, lng: order.address.long });
      mapRef.current.fitBounds(bounds);
    }
  }, [order]);

  return (
    <Stack direction={'column'} gap={'8px'}>
      <Card
        variant="custom"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Stack>
          <CardContent
            sx={{
              px: '12px',
              py: '8px !important'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Stack
                sx={{ alignItems: 'center' }}
                direction={'row'}
                gap={'12px'}
              >
                <img src={character_driver} width={36} height={36} />
                <Stack gap={'5px'}>
                  <Typography
                    fontWeight={'600'}
                    fontSize={'14px'}
                    lineHeight={'14px'}
                  >
                    {order.driver.name}
                  </Typography>
                  <Typography
                    fontWeight={'400'}
                    lineHeight={'12px'}
                    fontSize={'12px'}
                    sx={{ color: grey[600] }}
                  >
                    {order.driver_delivered_orders == 1
                      ? `${order.driver_delivered_orders} entrega realizada`
                      : `${order.driver_delivered_orders} entregas realizadas`}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </CardContent>
        </Stack>
        <Stack position={'relative'}>
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_ID || ''}
          >
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={10}
              onLoad={(map) => {
                mapRef.current = map;
              }}
              options={{
                disableDefaultUI: true,
                zoomControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                mapTypeControl: false
              }}
            >
              <Marker
                position={{
                  lat: order.driver_latitude,
                  lng: order.driver_longitude
                }}
                icon={{
                  url: icon_driver,
                  scaledSize:
                    window?.google?.maps &&
                    window?.google?.maps?.Size &&
                    new window.google.maps.Size(40, 40),
                  origin:
                    window?.google?.maps &&
                    window?.google?.maps?.Point &&
                    new window.google.maps.Point(0, 0),
                  anchor:
                    window?.google?.maps &&
                    window?.google?.maps?.Point &&
                    new window.google.maps.Point(20, 40)
                }}
              />
              <Marker
                position={{
                  lat: order.address.lat,
                  lng: order.address.long
                }}
                icon={{
                  url: icon_pin,
                  scaledSize:
                    window?.google?.maps && new window.google.maps.Size(40, 40),
                  origin:
                    window?.google?.maps && new window.google.maps.Point(0, 0),
                  anchor:
                    window?.google?.maps && new window.google.maps.Point(20, 40)
                }}
              />
            </GoogleMap>
          </LoadScript>
          {order.confirmation_code && (
            <Stack
              position={'absolute'}
              bottom={'16px'}
              px={'16px'}
              width={'100%'}
            >
              <Stack
                width={'100%'}
                height={'45px'}
                bgcolor={'#fff'}
                borderRadius={'8px'}
                p={'8px'}
                border={`solid 1px ${grey[300]}`}
              >
                <Stack
                  width={'100%'}
                  height={'100%'}
                  bgcolor={'#E5F5FF80'}
                  borderRadius={'4px'}
                  border={`solid 1px #6695C980`}
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  px={'12px'}
                >
                  <Stack direction={'row'} alignItems={'center'} gap={'4px'}>
                    <LockOutlined
                      sx={{
                        fontSize: '18px',
                        color: theme.palette.primary.dark
                      }}
                    />
                    <Typography
                      fontSize={'14px'}
                      fontWeight={'500'}
                      lineHeight={'14px'}
                      color={grey[900]}
                    >
                      Código de entrega
                    </Typography>
                    <Help
                      sx={{
                        color: theme.palette.grey[600],
                        fontSize: '16px',
                        ml: '6px',
                        cursor: 'pointer'
                      }}
                      onClick={() => setIsModalOpen(true)}
                    />
                  </Stack>
                  {order.confirmation_code}
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Card>
      <ConfirmationCodeModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </Stack>
  );
}
