import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Stack,
  Box
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

export interface IModalProps {
  open: boolean;
  onClose: () => void;
}

export default function RankingAppgasModal(props: IModalProps) {
  const { onClose, open } = props;

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <DialogTitle>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography fontSize={'22px'} fontWeight={400} color={'#1C3B77'}>
            Legenda das revendas
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
              p: 0
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography fontSize={'14px'} fontWeight={500} color={'#757575'}>
          Entenda as classificações das revendas:
        </Typography>
        <Stack
          sx={{
            border: '1px solid #E0E0E0',
            mt: '8px'
          }}
          borderRadius={'10px'}
        >
          <Stack p={'8px 9px'} borderBottom={'1px solid #E0E0E0'}>
            <Box
              px={'4px'}
              py={'1.5px'}
              sx={{ backgroundColor: '#ABFAF7' }}
              borderRadius={'4px'}
              gap={'4px'}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={'max-content'}
            >
              <DiamondOutlinedIcon
                sx={{
                  color: '#0E4F7C',
                  fontSize: '14px'
                }}
              />
              <Typography
                variant="body1"
                fontSize={'12px'}
                fontWeight={'500'}
                color={'#0E4F7C'}
              >
                Revenda Diamante
              </Typography>
            </Box>
            <Typography fontSize={'12px'} fontWeight={400} color={'#757575'}>
              As revendas diamante são as{' '}
              <strong>melhores entre todas as revendas</strong> da Appgas e sua
              experiência será incrível!
            </Typography>
          </Stack>
          <Stack p={'8px 9px'} borderBottom={'1px solid #E0E0E0'}>
            <Box
              px={'4px'}
              py={'1.5px'}
              sx={{ backgroundColor: '#FFE39F' }}
              borderRadius={'4px'}
              gap={'4px'}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={'max-content'}
            >
              <WorkspacePremiumOutlinedIcon
                sx={{
                  color: '#934605',
                  fontSize: '14px'
                }}
              />
              <Typography
                variant="body1"
                fontSize={'12px'}
                fontWeight={'500'}
                color={'#934605'}
              >
                Revenda Ouro
              </Typography>
            </Box>
            <Typography fontSize={'12px'} fontWeight={400} color={'#757575'}>
              As revendas ouro vem logo abaixo das diamantes e estão preparadas
              para prestar um serviço de excelência!
            </Typography>
          </Stack>
          <Stack p={'8px 9px'} borderBottom={'1px solid #E0E0E0'}>
            <Box
              px={'4px'}
              py={'1.5px'}
              sx={{ backgroundColor: '#E0E0E0' }}
              borderRadius={'4px'}
              gap={'4px'}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={'max-content'}
            >
              <WorkspacePremiumOutlinedIcon
                sx={{
                  color: '#424242',
                  fontSize: '14px'
                }}
              />
              <Typography
                variant="body1"
                fontSize={'12px'}
                fontWeight={'500'}
                color={'#424242'}
              >
                Revenda Prata
              </Typography>
            </Box>
            <Typography fontSize={'12px'} fontWeight={400} color={'#757575'}>
              As revendas prata vem logo abaixo das ouro e prestam um ótimo
              serviço.
            </Typography>
          </Stack>
          <Stack p={'8px 9px'} borderBottom={'1px solid #E0E0E0'}>
            <Box
              px={'4px'}
              py={'1.5px'}
              sx={{ backgroundColor: '#FFEFEA' }}
              borderRadius={'4px'}
              gap={'4px'}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={'max-content'}
            >
              <WorkspacePremiumOutlinedIcon
                sx={{
                  color: '#9B1843',
                  fontSize: '14px'
                }}
              />
              <Typography
                variant="body1"
                fontSize={'12px'}
                fontWeight={'500'}
                color={'#9B1843'}
              >
                Revenda Bronze
              </Typography>
            </Box>
            <Typography fontSize={'12px'} fontWeight={400} color={'#757575'}>
              As revendas bronze prestam um bom serviço e estão acima das
              revendas sem classificação.
            </Typography>
          </Stack>
          <Stack p={'8px 9px'} borderBottom={'1px solid #E0E0E0'}>
            <Box
              px={'4px'}
              py={'1.5px'}
              sx={{ backgroundColor: '#E5F5FF' }}
              borderRadius={'4px'}
              gap={'4px'}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={'max-content'}
            >
              <BoltOutlinedIcon
                sx={{
                  color: '#1C3B77',
                  fontSize: '14px'
                }}
              />
              <Typography
                variant="body1"
                fontSize={'12px'}
                fontWeight={'500'}
                color={'#1C3B77'}
              >
                Entrega Rápida
              </Typography>
            </Box>
            <Typography fontSize={'12px'} fontWeight={400} color={'#757575'}>
              Essas são as revendas <strong>mais rápidas da Appgas</strong>,
              possuem um tempo médio abaixo de 30min.
            </Typography>
          </Stack>
          <Stack p={'8px 9px'} borderBottom={'1px solid #E0E0E0'}>
            <Box
              px={'4px'}
              py={'1.5px'}
              sx={{ backgroundColor: '#EEEEEE' }}
              borderRadius={'4px'}
              gap={'4px'}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={'max-content'}
            >
              <BookmarkOutlinedIcon
                sx={{
                  color: '#757575',
                  fontSize: '14px'
                }}
              />
              <Typography
                variant="body1"
                fontSize={'12px'}
                fontWeight={'500'}
                color={'#757575'}
              >
                Última Compra
              </Typography>
            </Box>
            <Typography fontSize={'12px'} fontWeight={400} color={'#757575'}>
              Essa foi a revenda que você realizou seu último pedido.
            </Typography>
          </Stack>
          <Stack p={'8px 9px'}>
            <Box
              px={'4px'}
              py={'1.5px'}
              sx={{ backgroundColor: '#CDF7B7' }}
              borderRadius={'4px'}
              gap={'4px'}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={'max-content'}
            >
              <EmojiEventsIcon
                sx={{
                  color: '#146A20',
                  fontSize: '14px'
                }}
              />
              <Typography
                variant="body1"
                fontSize={'12px'}
                fontWeight={'500'}
                color={'#146A20'}
              >
                Preço Campeão
              </Typography>
            </Box>
            <Typography fontSize={'12px'} fontWeight={400} color={'#757575'}>
              Essa é a revenda que possui o <strong>melhor preço</strong> para o
              seu endereço, nesse momento.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
