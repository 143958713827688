/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { Container } from '../../components/Container';
import CancelModal from './components/CancelModal';
import ReasonsModal from './components/ReasonsModal';
import { formatPrice } from '../../helpers/formatPrice';

import {
  Box,
  Typography,
  useTheme,
  Dialog,
  DialogContent,
  Button,
  Stack
} from '@mui/material';
import DeliveryTime from './components/DeliveryTime';
import { ButtonsBox, ConfirmButton } from './components/CancelModal/styles';
import { Close as CloseIcon } from '@mui/icons-material';
import { useHeader } from 'hooks/useHeaderContext';
import PixInAppModal from './components/PixInAppModal';
import { OrderContext } from 'providers/order-context/order.context';
import { DeliveryInfos } from './components/DeliveryInfos';
import { CartProductsList } from './components/CartProductsList';
import { DriverMap } from './components/DriverMap';

export default function Review() {
  const theme = useTheme();
  const { palette } = useTheme();
  const { order } = useContext(OrderContext);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openResellerModal, setOpenResellerModal] = useState(false);
  const [openReasonsModal, setOpenReasonsModal] = useState(false);
  const { setSubtitle, setTitle, setEditAddress } = useHeader();
  const [isPixInAppModalOpen, setIsPixInAppModalOpen] =
    useState<boolean>(false);

  const [orderStatusId, setOrderStatusId] = useState<number>(0);
  const [isSeparatingOrder, setIsSeparatingOrder] = useState<boolean>(false);

  const isOrderStatusIdExpiredOrProcessing =
    orderStatusId == 4 || orderStatusId == 5;

  const items_string = order?.products
    .map(
      (p) =>
        'product: ' + p.name + ' x' + p.quantity + ' - price: ' + p.unit_price
    )
    .join(', ');

  useEffect(() => {
    order?.payment_method?.slug == 'inapp_pix' && setIsPixInAppModalOpen(true);
  }, [order, order?.payment_method]);

  useEffect(() => {
    setTitle('Acompanhe seu pedido');
    setEditAddress(false);
  }, [setSubtitle, setTitle]);

  if (!order?.id) return <></>;

  if (!order) return <></>;

  return (
    <>
      <Box sx={{ backgroundColor: '#FFFF' }}>
        <Container>
          <Box
            sx={{
              py: '24px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <Box>
              <DeliveryTime
                orderId={order.id}
                currency="BRL"
                couponCode={order.coupon}
                items={items_string || ''}
                paymentType={order?.payment_method?.name}
                value={order.total}
                getStatus={(statusId: number) => setOrderStatusId(statusId)}
                getIsSeparatingOrder={(isSeparatingOrder: boolean) =>
                  setIsSeparatingOrder(isSeparatingOrder)
                }
                driver={order.driver}
                clientPhone={order.user.phone}
              />
            </Box>
          </Box>
        </Container>
      </Box>

      <Container>
        <Stack sx={{ mt: '2rem' }} gap={'16px'} direction={'column'}>
          {order.driver_latitude &&
            !isSeparatingOrder &&
            orderStatusId !== 0 && <DriverMap order={order} />}
          <DeliveryInfos
            order={order}
            orderStatusId={orderStatusId}
            isSeparatingOrder={isSeparatingOrder}
          />
          <CartProductsList />
        </Stack>

        <Box sx={{ my: '16px', textAlign: 'center' }}>
          {order?.payment_method?.slug === 'inapp_pix' &&
            isOrderStatusIdExpiredOrProcessing && (
              <Button
                variant="contained"
                color="success"
                sx={{
                  my: '20px',
                  fontWeight: '700',
                  padding: '9px 16px',
                  backgroundColor: theme.palette.green['xdark'],
                  '&:hover': {
                    backgroundColor: theme.palette.green['xxdark']
                  }
                }}
                onClick={() => setIsPixInAppModalOpen(true)}
              >
                Confirmar o pagamento
              </Button>
            )}

          <Typography
            sx={{
              color: palette.primary.xdark,
              fontSize: '0.9rem',
              fontWeight: 'bold',
              cursor: 'pointer'
            }}
            onClick={() => setOpenCancelModal(true)}
          >
            MAIS AÇÕES
          </Typography>
        </Box>
      </Container>
      <CancelModal
        open={openCancelModal}
        setOpenReasonsModal={() => setOpenReasonsModal(true)}
        order={order}
        onClose={() => setOpenCancelModal(false)}
      />

      <ReasonsModal
        open={openReasonsModal}
        order={order}
        onClose={() => setOpenReasonsModal(false)}
      />
      {order?.payment_method?.slug === 'inapp_pix' &&
        orderStatusId &&
        isOrderStatusIdExpiredOrProcessing && (
          <PixInAppModal
            open={isPixInAppModalOpen}
            createdAt={order.date}
            pixCode={order.payment_qrcode || ''}
            pixCodeImage={order.payment_qrcode_image || ''}
            onClose={() => setIsPixInAppModalOpen(false)}
            price={formatPrice(order.total)}
          />
        )}

      <Dialog
        open={openResellerModal}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => setOpenResellerModal(false)}
        sx={{ padding: '24px' }}
      >
        <DialogContent>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                color: '#3968A6',
                fontSize: '22px',
                mb: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                [theme.breakpoints.down('md')]: {
                  fontSize: '16px'
                }
              }}
            >
              <span style={{ color: theme.palette.primary.main }}>
                Entrega não rastreável
              </span>
              <CloseIcon
                sx={{ cursor: 'pointer', color: '#BDBDBD' }}
                onClick={() => setOpenResellerModal(false)}
              />
            </Box>
            <Box sx={{ color: '#9E9E9E', fontSize: '14px' }}>
              A revenda que você escolheu para o seu pedido é responsável pela
              entrega.
            </Box>
            <ButtonsBox
              sx={{
                alignItems: 'end',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'left'
              }}
            >
              <ConfirmButton
                sx={{
                  width: '91px',
                  height: '48px'
                }}
                variant="contained"
                onClick={() => setOpenResellerModal(false)}
              >
                ENTENDI
              </ConfirmButton>
            </ButtonsBox>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
