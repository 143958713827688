export function formatPhoneNumber(phone: string): string {
  const cleaned = phone.replace(/\D/g, '');

  let countryCode = '55';
  let localNumber = cleaned;

  if (cleaned.length > 11) {
    countryCode = cleaned.slice(0, 2);
    localNumber = cleaned.slice(2);
  }

  const ddd = localNumber.slice(0, 2);
  const mainNumber = localNumber.slice(2);

  const hasNineDigits = mainNumber.length === 9;
  const firstPart = mainNumber.slice(0, hasNineDigits ? 5 : 4);
  const secondPart = mainNumber.slice(hasNineDigits ? 5 : 4);

  return `+${countryCode} ${ddd} ${firstPart}-${secondPart}`;
}
